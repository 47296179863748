<template>
    <div>
        <v-card>
            <v-card-title>
                <v-icon color="primary">
                    mdi-account-cash-outline
                </v-icon>
                <span class="pl-2 pr-2">Transferirir Moedas</span>
                <info-tool-tip
                    message="Pode transferir moedas para os pais."
                ></info-tool-tip>
            </v-card-title>
            <v-data-table
                item-key="id"
                :loading="isLoading"
                loading-text="A carregar... Por favor, aguarde"
                :headers="headers"
                :items="parents"
                :search="search"
            >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        label="Pesquisar"
                        class="mx-4"
                    ></v-text-field>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        depressed
                        color="primary"
                        @click="transfer(item)"
                        class="mx-1"
                    >
                        Transferir
                        <v-icon right dark> mdi-check </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import InfoToolTip from "@/components/InfoToolTip";
export default {
    components: {
        InfoToolTip,
    },
    data() {
        return {
            parents: [],
            isLoading: true,
            search: "",
            planDialog: false,
            valid: true,
            id: null,
            planTypeNome: "",
            selectedPlan: null,
            headers: [
                { text: "ID", value: "id", align: "start", filterable: false },
                { text: "E-mail", value: "email" },
                { text: "Nome", value: "name" },
                { text: "Saldo", value: "balance" },
                { text: "Ações", value: "actions", sortable: false },
            ],
        };
    },
    mounted() {
        this.loadParents();
    },
    methods: {
        loadParents() {
            this.isLoading = true;
            this.parents = [];
            axios
                .get('/users/all-parents')
                .then(async (response) => {
                    this.parents = response.data.parents;
                })
                .catch((error) => {
                    this.$notify({
                        title: "Erro",
                        text: "Erro while retrieving parents",
                        type: "error",
                    });
                    console.log(error);
                    this.$swal("Erro", error.response.data.message, "error");
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
        transfer(parent) {
            this.$swal
                .fire({
                    title: "Transferirir Moedas",
                    text: "Insira quantas moedas deseja transferir para " + parent.name,
                    input: "number",
                    inputAttributes: {
                        min: 1,
                    },
                    inputValidator: (value) => {
                        if (!value || value <=0) {
                            return "Por favor, insira um número válido";
                        }
                    },
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Transferir",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let coins = result.value;
                        this.isLoading = true;
                        axios
                            .post("/users/transfer-coins", {
                                parent_id: parent.id,
                                coins: coins,
                            })
                            .then((response) => {
                                this.$notify({
                                    title: "Sucesso",
                                    text: "Moedas transferidas com sucesso",
                                    type: "success",
                                });
                                //update parent balance with coins
                                let index = this.parents.findIndex((p) => p.id === parent.id);
                                this.parents[index].balance = Number(this.parents[index].balance) + Number(coins);
                            })
                            .catch((error) => {
                                this.$notify({
                                    title: "Erro",
                                    text: "Erro while transferring coins",
                                    type: "error",
                                });
                                console.log(error);
                                this.$swal("Erro", error.response.data.message, "error");
                            })
                            .then(() => {
                                this.isLoading = false;
                            });
                    }
                });
        },
    },
};
</script>
